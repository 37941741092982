/* CardGrid.scss */
* {
  margin: 0px;
  padding: 0px;
}

.admin-container {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem 2rem 13rem;

  h1 {
    font-family: Oxanium;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
  }

  span {
    font-family: Oxanium;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
  }

  .card-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .card-wrapper {
    margin: 1rem;
    display: flex;
  }

  .card {
    background: #f5f5f5;
    border-radius: 20px;
    max-width: 500px;
    height: 400px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card_image {
    max-width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    gap: 0.5rem;
  }
  .card_title {
    font-family: Oxanium;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
  }

  .card_subtitle {
    font-family: Oxanium;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #000;
  }
}

@media (max-width: 768px) {
  .admin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-top: 1rem;
  }
  .card-grid {
    flex-direction: column;
    align-items: center;
  }

  .card-wrapper {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }

  .card {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .admin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-top: 1rem;
  }
  .card {
    max-width: 100%;
  }
}
// section-2
.section-2-container {
  .text-container {
    display: flex;
    flex-direction: column;
    height: 300px;
    background: #f5f5f5;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    h1 {
      font-family: Oxanium;
      font-size: 44px;
      font-weight: 600;
      line-height: 55px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }

    p {
      width: 950px;
      font-family: Oxanium;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #313131;
    }
  }

  .container-2 {
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      width: 400px;
      height: 450px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: transparent;
      align-items: end;

      h1 {
        font-family: Oxanium;
        font-size: 44px;
        font-weight: 600;
        line-height: 55px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4b4a4b;
      }

      p {
        width: 500px;
        font-family: Oxanium;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #313131;
      }
    }
  }

  .container-3 {
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    h1 {
      font-family: Oxanium;
      font-size: 44px;
      font-weight: 600;
      line-height: 55px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }
    .image-container {
      display: flex;
      gap: 3rem;

      .box {
        display: flex;
        flex-direction: column;
        height: 434px;
        border-radius: 20px;
        gap: 2rem;
        align-items: center;
        background-color: rgb(219, 215, 215);
        img {
          width: 100%;
          height: 300px;
        }

        h1 {
          width: 270px;
          font-family: Oxanium;
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .section-2-container {
    .text-container {
      h1 {
        font-size: 36px;
        line-height: 45px;
      }

      p {
        width: 80%; /* Adjust width for smaller screens */
      }
    }

    .container-2 {
      flex-direction: column;
      height: auto;

      img {
        width: 90%;
        height: auto;
      }

      .text-container {
        align-items: center;
        h1 {
          text-align: center;
        }

        p {
          width: 80%;
          text-align: center;
        }
      }
    }

    .container-3 {
      flex-direction: column;
      gap: 1rem;
      height: auto;

      h1 {
        font-size: 36px;
        line-height: 45px;
      }

      .image-container {
        flex-direction: column;
        gap: 2rem;

        .box {
          width: 100%;
          height: 400px;

          img {
            height: 300px;
          }

          h1 {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section-2-container {
    .text-container {
      h1 {
        font-size: 28px;
        line-height: 36px;
      }

      p {
        width: 90%;
      }
    }

    .container-2 {
      img {
        width: 100%;
      }

      .text-container {
        p {
          width: 100%;
        }
      }
    }

    .container-3 {
      .image-container {
        .box {
          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .section-2-container {
    .text-container {
      h1 {
        font-size: 24px;
        line-height: 32px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .container-2 {
      img {
        width: 100%;
      }

      .text-container {
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .container-3 {
      .image-container {
        .box {
          h1 {
            font-size: 16px;
          }
        }
      }
    }
  }
}
//section-3
.section-3-container {
  .image-container {
    .box-1,
    .box-2 {
      height: 520px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      img {
        width: 500px;
        height: 416px;
        border-radius: 20px 200px 20px 20px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h1 {
          font-family: Oxanium;
          font-size: 44px;
          font-weight: 600;
          line-height: 55px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;
        }
        img {
          width: 90px;
          height: 30px;
        }
        p {
          width: 480px;
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #313131;
        }
      }
    }

    .box-2 {
      img {
        width: 500px;
        height: 418px;
        border-radius: 200px 20px 20px 20px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        background: transparent;
        align-items: end;
      }
    }
  }

  .image-container-2 {
    margin-bottom: 2rem;
    .box-1,
    .box-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        h1 {
          font-family: Oxanium;
          font-size: 44px;
          font-weight: 600;
          line-height: 55px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;
        }
        p {
          width: 500px;
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #313131;
        }
      }
    }

    .box-2 {
      .text-container {
        h1 {
          width: 450px;
        }

        button {
          width: 237px;
          height: 46px;
          border-radius: 9999px;
          background: #00d2c0;

          span {
            font-family: Oxanium;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .section-3-container {
    margin-top: 1rem;
    .image-container {
      .box-1,
      .box-2 {
        flex-direction: column; /* Stack content vertically */
        gap: 1.5rem;

        img {
          width: 90%; /* Adjust image size for responsiveness */
          height: auto; /* Maintain aspect ratio */
        }

        .text-container {
          align-items: center; /* Center text for smaller screens */
          text-align: center;

          h1 {
            font-size: 36px;
            line-height: 45px;
          }

          p {
            width: 80%; /* Adjust paragraph width */
          }
        }
      }
    }

    .image-container-2 {
      .box-1,
      .box-2 {
        flex-direction: column; /* Stack content vertically */
        gap: 1.5rem;
        img {
          width: 100%;
          height: 308px;
        }
        .text-container {
          align-items: center;
          text-align: center;

          h1 {
            font-size: 36px;
            line-height: 45px;
          }

          p {
            width: 80%; /* Adjust paragraph width */
          }

          button {
            width: 200px;
            height: 40px;

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section-3-container {
    margin-top: 1rem;

    .image-container {
      .box-1,
      .box-2 {
        img {
          width: 100%; /* Full width on smaller screens */
        }

        .text-container {
          h1 {
            font-size: 28px;
            line-height: 36px;
          }

          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .image-container-2 {
      .box-1,
      .box-2 {
        img {
          width: 100%;
          height: 308px;
        }
        .text-container {
          h1 {
            font-size: 28px;
            line-height: 36px;
          }

          p {
            font-size: 14px;
            line-height: 18px;
          }

          button {
            width: 180px;
            height: 36px;

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .section-3-container {
    margin-top: 2rem;

    .image-container {
      .box-1,
      .box-2 {
        img {
          width: 380px;
          height: 400px;
        }

        .text-container {
          h1 {
            font-size: 24px;
            line-height: 32px;
          }

          p {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .box-2{
        flex-direction: column-reverse;
      }
    }

    .image-container-2 {
      .box-1,
      .box-2 {
        img {
          width: 100%;
          height: 308px;
        }
        .text-container {
          h1 {
            width: 250px;
            font-size: 24px;
            line-height: 32px;
          }

          p {
            font-size: 12px;
            line-height: 16px;
          }

          button {
            width: 160px;
            height: 32px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
