.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
  @media (max-width: 490px) {
    padding: 0.5em;
  }
  .content {
    font-weight: 800;
    li {
      font-family: Oxanium;
      margin: 0 2em;
      padding: 0.5em;
      text-indent: 0;
      // list-style-type: decimal;
      @media (max-width: 490px) {
        padding: 0;
        margin: 0 0.5em;
      }
      .sub-content {
        .sub-conten {
            font-family: Oxanium;
            font-weight: 100;
            padding: 1em;
            text-indent: 0;
            list-style-type: disc;
            @media (max-width: 490px) {
              padding: 0;
              font-weight: 100;

              text-indent: 0;
              list-style-type: disc;
            }
        }
      }
    }
  }
}
