.container-1 {
  margin-top: 8%;
  height: 25em;
  display: flex;
  flex-direction: row;
  background-color: #FEFFEE;
  @media (max-width: 900px) {
    gap: 5em;
  }
  @media (max-width: 770px) {
    flex-direction: column;
    gap: 25em;
    height: auto;
  }

  @media (max-width: 490px) {
    flex-direction: column;
    gap: 15em;
  }

  .text-box {
    justify-content: center;
    flex: 1;
    font-family: "Oxanium";
    display: flex;
    flex-direction: column;
    padding: 2% 2% 2% 20%;
    text-align: justify;
    align-items: flex-end;
    gap: 0.5em;

    @media (max-width: 1100px) {
      padding: 1% 1% 1% 10%;
    }
    h2 {
      color: #4b4a4b;
    }
    p {
      font-family: "Oxanium";
      color: #313131;
    }
  }
  .image-box {
    width: 50%;
    margin-top: -2%;
    .image-spinner-container {
      position: relative;
      top:-3em;
      .spinner {
        position: absolute;
        top: 4em;
        left: 7em;
      }
      .image-container {
        position: absolute;
        right: -2em;
        z-index: 1;
        width: 100%;
        @media (max-width: 490px) {
          right: 0;
          top: 2em;
        }
        img {
          @media (max-width: 490px) {
            width: 18em !important;
          }
          @media (max-width: 1100px) {
            width: 36em;
          }
        }
      }
    }
  }
}
