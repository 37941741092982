.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
  ul,li{
    margin: 0;
    padding: 1em 0;
    text-indent: 0;
    font-weight: bold;
    list-style-type: disclosure-closed;
  }
}
