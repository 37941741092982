.phone {
  padding-top: 2em;
  display: flex;
  gap: 2em;
  @media (max-width: 426px) {
    flex-direction: column;
  }
  .left {
    width: 50%;
    display: flex;
    padding: 0 2em;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    @media (max-width: 490px) {
      width: 100%;
      padding: 0;
    }

    h2 {
      font-family: Oxanium;
      font-size: x-large;
      padding-bottom: 1em;
      @media (max-width: 490px) {
        font-size: large;
      }
    }
    .tilted-rectangles {
      display: flex;
      gap: 10px;
      padding: 0 0 1em 0.8em;
      div {
        width: 1em;
        height: 1.2em;
        background-color: #53778d;
        transform: skewX(-20deg);
        border-radius: 5px;
      }
    }
    p {
      font-family: "Oxanium";
      font-weight: 500;
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: end;
    .imphon {
      width: 100%;
    }
  }
}
