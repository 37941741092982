.learning-section {
  padding: 40px;
  background-color: #ffffff;
  @media (max-width: 490px) {
    padding: 1em 0 1em 2em;
  }
  &__title {
    font-family: "Oxanium";
    font-size: 2.8em;
    padding-right: 8em;
    font-weight: 700;
    margin-bottom: 32px;
    color: #4B4A4B;
    @media (max-width: 490px) {
      font-size: larger;
      padding: 0;
    }

  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: stretch;
  }
}

.learning-card {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 35em;
  align-items: center;
  background-color: #DEF1F0; /* Light gray background */
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: 490px) {
  height: auto; 
  }

  &__image {
    width: 100%;
    height: 50%;
    object-fit: cover;
  }

  &__content {
    font-family: 'Oxanium';
    padding: 16px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 2em;

    &__title {
      font-family: 'Oxanium';
      font-size: 1.5em;
      font-weight: bolder;
      color: #4B4A4B;
      margin: 12px 0;
    }

    &__description {
      font-size: 0.9rem;
      color: #000000;
      font-weight: 500;
      font-family: 'Oxanium';
      margin-bottom: 16px;
      @media (max-width: 426px) {
          font-size: 1.2em;
        }
    }

    &__link {
      display: flex;
      // align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 0.9rem;
      color: black; /* Light blue link */
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
