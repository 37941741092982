.home-container {
  width: 100%;
  height: 100%;

  .container-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #f2f2f2;
    padding: 3rem 0;

    img {
      width: 400px;
      max-width: 400px;
      height: auto;
      border-radius: 340px;
    }

    .text-container {
      width: 100%;
      max-width: 530px;

      h3 {
        font-family: Oxanium;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #313131;
      }

      p {
        font-family: Oxanium;
        font-size: 44px;
        font-weight: 600;
        line-height: 55px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4b4a4b;
      }
    }
  }

  .container-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 3rem 0;
    background: white;

    img {
      width: 400px;
      max-width: 400px;
      height: auto;
      border-radius: 340px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      width: 571.7px;
      height: 273.9px;
      gap: 1rem;

      img {
        width: 88.96px;
        height: 27.99px;
      }

      h3 {
        font-family: Oxanium;
        font-size: 44px;
        font-weight: 600;
        line-height: 55px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4b4a4b;
      }

      p {
        font-family: Oxanium;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #313131;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-container {
    padding: 0.5rem;

    .container-1 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: #f2f2f2;
      gap: 1rem;

      img {
        max-width: 240px;
        height: auto;
        border-radius: 340px;
      }

      .text-container {
        max-width: 350px;

        h3 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }

        p {
          width: 350px;
          font-size: 20px;
          font-weight: 700;
          line-height: 25px;
          text-align: center;
        }
      }
    }

    .container-2 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;

      /* Reduced gap */
      img {
        max-width: 240px;
        height: auto;
        border-radius: 340px;
      }

      .text-container {
        max-width: 350px;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          width: 88.96px;
          height: 27.99px;
        }

        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
          text-align: center;
        }

        p {
          width: 350px;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home-container {
    padding: 0.5rem;

    .container-1 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: #f2f2f2;

      img {
        max-width: 240px;
        height: auto;
        border-radius: 340px;
      }

      .text-container {
        max-width: 350px;

        h3 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }

        p {
          width: 350px;
          font-size: 20px;
          font-weight: 700;
          line-height: 25px;
          text-align: center;
        }
      }
    }

    .container-2 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;

      /* Reduced gap */
      img {
        max-width: 240px;
        height: auto;
        border-radius: 340px;
      }

      .text-container {
        max-width: 350px;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          width: 88.96px;
          height: 27.99px;
        }

        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
          text-align: center;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }
      }
    }
  }
}

//section-2 styling

.section-2-container {
  width: 100%;
  height: 100%;
  background: #fffff5;
  padding: 2rem 7rem;

  .section-2-content {
    width: 100%;
    height: 100%;
    border-radius: 20px;

    h2 {
      font-family: Oxanium;
      font-size: 44px;
      font-weight: 600;
      line-height: 55px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }

    .box-container {
      width: 100%;
      height: auto;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 2rem;

      .box-content {
        width: 100%;
        max-width: 537px;
        height: auto;
        padding: 0.5rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .box {
          padding: 2rem;
          border-radius: 10px;
          transition: all 0.3s ease-in-out;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 1.5rem;
          height: 500px;
          width: 540px;

          img {
            width: 100px;
            height: 100px;
          }

          h3 {
            width: 100%;
            max-width: 300px;
            height: auto;
            font-family: Oxanium;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #4b4a4b;
          }

          p {
            width: 100%;
            max-width: 300px;
            height: auto;
            font-family: Oxanium;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #313131;
          }

          &:hover {
            border-color: #007bff;
            box-shadow: 0 8px 16px rgba(4, 45, 90, 0.2);
            background: #fff;
            border-radius: 20px;
        
            p {
              height: auto;
              color: #333;
            }
          }
          .img {
            width: 89px;
            height: 28px;
          }
        }

        
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .section-2-container {
    padding: 2rem 1rem;

    .section-2-content {
      h2 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }

      .box-container {
        .box-content {
          .box {
            height: auto;
      
            h3 {
              font-size: 24px;
              line-height: 30px;
            }
      
            p {
              font-size: 14px;
              line-height: 18px;
              height: auto; 
            }
          }
      
          img {
            width: 89px;
            height: 28px;
          }
      
          .img {
            width: 70px;
            height: 22px;
          }
        }

      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section-2-container {
    padding: 2rem 3rem;

    .section-2-content {
      h2 {
        font-size: 36px;
        line-height: 45px;
        text-align: center;
      }

      .box-container {
        gap: 1.5rem;

        .box-content {
          .box {
            h3 {
              font-size: 28px;
              line-height: 35px;
            }

            p {
              font-size: 16px;
              line-height: 20px;
            }
          }

          img {
            width: 89px;
            height: 28px;
          }
        }

        img {
          width: 80px;
          height: 25px;
        }
      }
    }
  }
}

//section-3 styling
.section-3-container {
  padding: 2rem 7rem;

  .section-3-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    img {
      width: 89px;
      height: 28px;
      margin-bottom: 1rem;
    }

    h1 {
      font-family: Oxanium;
      font-size: 44px;
      font-weight: 600;
      line-height: 55px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }

    .box-container {
      margin-bottom: 3rem;

      .box-content {
        h2 {
          font-family: Oxanium;
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;
          margin-bottom: 1rem;
        }

        p {
          width: 700px;
          height: auto;
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #313131;
          margin-bottom: 2rem;
        }
      }

      .box,
      .box-2,
      .box-3 {
        width: 100%;
        height: 416px;
        background: #e4d9f7;
        border-radius: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 2rem;
        @media screen and (max-width: 480px) {
          height: auto;
          
        }
      }
      
      .box-2 {
        background: #ffe6df;
        margin-top: 5rem;
        @media screen and (max-width: 480px) {
          margin-top: 0;
          
        }

      }

      .box-3 {
        background: #feffee;
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 2.5rem;
      

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        width: 100%;
        max-width: 604px;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        h3 {
          font-family: Oxanium;
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;

          span {
            font-family: Oxanium;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #6e6d6d;
          }
        }

        p {
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #313131;
        }

        .btn {
          cursor: pointer;
          width: 198px;
          height: 62px;
          padding: 21px 56px;
          gap: 10px;
          border-radius: 9999px;
          background: #00d2c0;
          border: none; /* Removes any default border */
        }
        
        .btn h3 {
          width: 86px;
          height: 20px;
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #ffffff;
        }
        
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .section-3-container {
    padding: 2rem 1rem;
  }

  .section-3-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .section-3-content img {
    width: 70px;
    height: 22px;
  }

  .section-3-content h1 {
    font-size: 8vw; /* Adjusts font size relative to viewport width */
    line-height: 10vw;
    text-align: center;
  }

  .section-3-content p {
    font-family: Oxanium;
    font-size: 3vw; /* Adjusts font size relative to viewport width */
    font-weight: 400;
    line-height: 4vw;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .box-content {
    width: 90vw; /* Ensures the box-content element fits within the viewport */
    text-align: -webkit-center;

  }

  .box-content h2 {
    font-size: 5vw; /* Adjusts font size relative to viewport width */
    line-height: 6vw;
    text-align: center;
  }

  .box-content p {
    max-width: 320px; /* Adjusts the width to fit within the viewport */
    font-family: Oxanium;
    font-size: 3vw; /* Adjusts font size relative to viewport width */
    font-weight: 400;
    line-height: 4vw;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .box,
  .box-3 {
    max-width: 100%;
    flex-direction: column-reverse;
    padding: 1rem;

  }

  .box-2 {
    flex-direction: column;
    padding: 1rem;
  }

  .text {
    width: 100%;
  }

  .text h3 {
    font-size: 6vw; /* Adjusts font size relative to viewport width */
    line-height: 7.5vw;
    text-align: center;
  }

  .text h3 span {
    font-size: 3.5vw; /* Adjusts font size relative to viewport width */
    line-height: 4.5vw;
  }

  .text p {
    font-size: 3.5vw; /* Adjusts font size relative to viewport width */
    line-height: 4.5vw;
  }

  .btn {
    cursor: pointer;
    width: 40vw; /* Adjusts width relative to viewport width */
    height: 12.5vw; /* Adjusts height relative to viewport width */
    padding: 3vw 6vw;
  }
}

@media screen and (max-width: 768px) {
  .section-3-container {
    padding: 2rem 1rem;

    .section-3-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 70px;
        height: 22px;
      }

      h1 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }

      P {
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .box-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .box-content {
          h2 {
            font-size: 20px;
            line-height: 25px;
            text-align: center;
          }

          p {
            width: 380px;
            font-family: Oxanium;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }

        .box,
        .box-3 {
          flex-direction: column-reverse;
          padding: 1rem;
        }
        .box-2 {
          flex-direction: column;
          padding: 1rem;
        }
        .text {
          width: 100%;

          h3 {
            font-size: 24px;
            line-height: 30px;
            text-align: center;

            span {
              font-size: 14px;
              line-height: 18px;
            }
          }

          p {
            font-size: 14px;
            line-height: 18px;
          }

          .btn {
            cursor: pointer;
            width: 150px;
            height: 50px;
            padding: 15px 30px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section-3-container {
    padding: 2rem 3rem;

    .section-3-content {
      img {
        width: 80px;
        height: 25px;
      }

      h1 {
        font-size: 36px;
        line-height: 45px;
        text-align: center;
      }

      .box-container {
        .box-content {
          h2 {
            font-size: 22px;
            line-height: 28px;
            text-align: center;
          }

          p {
            width: 100%;
            max-width: 400px;
            font-size: 15px;
            line-height: 20px;
          }
        }

        .box,
        .box-2,
        .box-3 {
          padding: 1.5rem;
        }

        .text {
          width: 100%;

          h3 {
            font-size: 28px;
            line-height: 35px;
            text-align: center;

            span {
              font-size: 15px;
              line-height: 20px;
            }
          }

          p {
            width: 300px;
            font-size: 15px;
            line-height: 20px;
          }

          .btn {
            cursor: pointer;
            width: 170px;
            height: 55px;
            padding: 18px 45px;
          }
        }
      }
    }
  }
}

//section-4 styling
.section-4-container {
  padding: 2rem 7rem;

  .section-4-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .box {
      display: flex;
      justify-content: space-between;

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        img {
          width: 89px;
          height: 28px;
        }

        h3 {
          font-family: Oxanium;
          font-size: 44px;
          font-weight: 600;
          line-height: 55px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;
        }

        p {
          width: 540px;
          height: 46px;
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #313131;
        }
      }
    }

    .box-2 {
      margin-top: 7rem;

      .text-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 676px;
        border-radius: 20px;
        background-image: linear-gradient(
            rgba(7, 114, 105, 0.44),
            rgba(7, 114, 105, 0.44)
          ),
          url("../../assets/images/brain.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 2rem;

        h3 {
          width: 520px;
          height: 110px;
          font-family: Oxanium;
          font-size: 44px;
          font-weight: 700;
          line-height: 55px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #ffffff;
        }

        p {
          width: 1000px;
          height: 90px;
          font-family: Oxanium;
          font-size: 18px;
          font-weight: 400;
          line-height: 22.5px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #dedede;
        }

        .btn {
          cursor: pointer;
          width: 330px;
          height: 72px;
          padding: 21px 89px 21px 89px;
          border-radius: 9999px;
          background: #00d2c0;
          border: none; /* Removes any default border */

          h2 {
            width: 153px;
            height: 30px;
            font-family: Oxanium;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #ffffff;
          }
        }
      }
    }

    .box-3 {
      width: 100%;
      height: 100%;
      margin-top: 7rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-box {
        width: 500px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        img {
          width: 89px;
          height: 28px;
        }

        h3 {
          font-family: Oxanium;
          font-size: 44px;
          font-weight: 600;
          line-height: 55px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4b4a4b;

          p {
            height: 92px;
            font-family: Oxanium;
            font-size: 18px;
            font-weight: 400;
            line-height: 22.5px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #313131;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section-4-container {
    padding: 2rem 2rem;

    .section-4-content {
      .box {
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .text-container {
          img {
            width: 60px;
            height: auto;
          }

          h3 {
            font-size: 32px;
            line-height: 40px;
          }

          p {
            width: 100%;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .box-2 {
        margin-top: 5rem;

        .text-container {
          padding: 3rem;
          height: 300px;

          h3 {
            width: 300px;
            font-family: Oxanium;
            font-size: 28px;
            font-weight: 600;
            line-height: 35px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          p {
            width: 350px;
            font-family: Oxanium;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          .btn {
            cursor: pointer;
            width: 300px;
            height: 52px;
            padding: 16px 69px 16px 69px;

            h2 {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }

      .box-3 {
        flex-direction: column;
        gap: 2rem;

        .text-box {
          width: 100%;

          h3 {
            font-size: 32px;
            line-height: 40px;
          }

          p {
            width: 350px;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .section-4-container {
    padding: 0rem;

    .section-4-content {
      .box {
        img {
          height: 210px;
          width: 210px;
        }
        .text-container {
          img {
            width: 50px;
          }

          h3 {
            font-size: 24px;
            line-height: 32px;
          }

          p {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .box-2 {
        .text-container {
          width: 100%;
          height: 476px;

          h3 {
            font-size: 24px;
          }

          p {
            width: auto;
            font-size: 12px;
          }
          a {
            text-decoration: none;
          }
          .btn {
            cursor: pointer;
            width: 200px;
            height: 52px;
            padding: 16px 69px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn h2 {
            font-size: 16px;
            font-family: Oxanium;
            margin: 0;
            white-space: nowrap;
          }
        }
      }

      .box-3 {
        img {
          width: 350px;
        }
        .text-box {
          h3 {
            font-size: 24px;
          }

          p {
            width: 350px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
