.testimonials {
  padding: 1em 0;
  .tilted-rectangles {
    display: flex;
    gap: 10px;
    padding: 0 0 1em 0.8em;
    div {
      width: 3em;
      height: 4em;
      background-color: #53778d;
      transform: skewX(-20deg);
      border-radius: 5px;
      @media (max-width: 490px) {
        width: 1em;
        height: 1.2em;
      }
    }
  }

  h2 {
    font-weight: 600;
    color: #4b4a4b;
    font-family: Oxanium;
    font-size: 3em;
    margin-bottom: 2rem;
    @media (max-width: 490px) {
      font-size: 1em;
    }
  }

  .overlay-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.75em, 1fr));
    gap: 1.25em;

    .card {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      transition:
        transform 0.3s,
        box-shadow 0.3s;

      &:hover {
        transform: translateY(-5px);
      }

      .card-image {
        width: 100%;
        height: 24.5em;
        object-fit: cover;
      }

      .card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #e3e3e378;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 1.25em;

        h3 {
          font-size: 1.125em;
          font-weight: bold;
          margin-bottom: 10px;
        }

        p {
          font-size: 0.875em;
          color: #ddd;
        }
      }
    }
  }
}
