.card-grid {
  display: flex;
  gap: 2em;
  padding: 2em;
  flex-direction: column;
  @media (max-width: 490px) {
    padding: 0;
    margin-bottom: 2em;
  }
  background-color: #f4f4f4;
  border-radius: 1em;
  .teams {
    display: flex;
    align-items: center;
    li{
      list-style-type: disclosure-open;
    }
    h2{
      @media (max-width: 490px) {
        font-size: large;
      }
    }
  }
  .card-image {
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-size: cover;
   img{
    width: 95%;
    height: 30em;
    border-radius: 1em;
  
    @media (max-width: 490px) {
      height: 15em;
    } 
  }
  }
  .who{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0 2em;
    
    @media (max-width: 490px) {
      padding: 0 0.5em;
    } 
    ul,li{
      margin: 0 1em;
    padding: 0.5em 0;
    text-indent: 0;
    list-style-type: disc;
    }
  }
}
