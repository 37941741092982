* {
  margin: 0;
  padding: 0;
}
.students-container {
  width: 100%;
  height: 100%;
  padding: 2em 5em;
  gap: 1em;
  background: white;
  .tilted-rectangles {
    display: flex;
    gap: 10px;
    padding: 0 0 1em 0.8em;
    div {
      width: 3em;
      height: 4em;
      background-color: #53778d;
      transform: skewX(-20deg);
      border-radius: 5px;
      @media (max-width: 490px) {
width: 1em;
height: 1.2em;
      }
    }
  }
  .students-txt {
    padding-top: 1em;
    font-size: 200%;
    @media (max-width: 490px) {
      font-size: 85%;
    }
  }
  h5 {
    width: auto;
    font-family: Oxanium;
    font-size: 18px;
    font-weight: 300;
    line-height: 22.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;

    span {
      font-family: Oxanium;
      font-size: 18px;
      font-weight: 700;
      line-height: 22.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }
  }

  h1 {
    font-family: Oxanium;
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
    margin: 40px 0px 20px 0px;
    @media (max-width: 490px) {
      font-size: 20px;
      margin: 0;
    }
  }
  
  @media (max-width: 769px) {
    padding: 2em 1em;
  }
  // @media (max-width: 426px) {
  //   padding:2em 1em;
  // }
  @media (max-width: 1100px) {
    padding: 0 1em;
  }
}
.container3 {
  // margin-bottom: 2em;
  background-color: white;
  height: 15em;
  .container {
    display: flex;
    height: 12em;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #efefef;
    text-align: center;
  }

  .heading {
    font-family: "Oxanium";
    font-weight: 600;
    font-size: 2.8em;
    color: #4b4a4b; // Dark text color
    margin-bottom: 20px;
    @media (max-width: 490px) {
      font-size: large;
    }
  }

  .button {
    background-color: #00d2c0; // Bright teal button color
    border-radius: 50px;
    font-size: 1rem;
    font-weight: bold;
    padding: 1em;
    color: white;

    &:hover {
      background-color: #00d2c0; // Slightly darker teal on hover
    }
  }
}
