.cardContainer {
  display: flex;
  flex-direction: column;
  .image_text_container-1 {
    display: flex;
    overflow: hidden;
    padding-top: 5%;
    gap: 2em;
    @media (max-width: 490px) {
      flex-direction: column;
      gap: 5em;
    }
    .container-1 {
      width: 60%;
      border-radius: 20px 100px 20px 20px;
      background: #ffeaea;
      display: flex;
      flex-direction: row;
      @media (max-width: 490px) {
        width: 100%;
        margin-top: 1em;
        flex-direction: column-reverse;
      }
      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10% 3%;
        align-items: center;
        text-align: center;
        h2 {
          @media (max-width: 1100px) {
            font-size: medium;
          }
        }
        h5 {
          @media (max-width: 1100px) {
            font-size: small;
          }
        }
        @media (max-width: 1100px) {
          padding: 0;
        }
      }
      .image-box {
        margin-top: -8%;
        .image-container {
          width: 100%;
          img {
            @media (max-width: 1100px) {
              width: 15em;
            }
          }
        }
        // width: 463px;
        // height: 462px;
      }
      @media (max-width: 1100px) {
        align-self: flex-start;
      }
    }
    .container-2 {
      flex: 1;
      border-radius: 20px 100px 20px 20px;
      background: #f2eaff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1% 3%;
        align-items: center;
        h2 {
          @media (max-width: 1100px) {
            font-size: medium;
          }
        }
        h5 {
          @media (max-width: 1100px) {
            font-size: small;
          }
        }
        @media (max-width: 1100px) {
          padding: 0 1em;
        }
      }
      .image-box {
        margin-top: -8%;
        .image-container {
          width: 100%;
          img {
            @media (max-width: 1100px) {
              width: 15em;
            }
          }
        }
        // width: 463px;
        // height: 462px;
      }
      @media (max-width: 1100px) {
        align-self: flex-start;
      }
    }
  }
  .image_text_container-2 {
    display: flex;
    overflow: hidden;
    padding-top: 5em;
    gap: 2em;
    @media (max-width: 490px) {
      flex-direction: column;
      gap: 5em;
    }
    .container-1 {
      width: 40%;
      border-radius: 100px 20px 20px 20px;
      background: #fff5df;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 490px) {
        width: 100%;
      }
      .text-box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1% 3%;
        align-items: center;
        h2 {
          @media (max-width: 1100px) {
            font-size: medium;
          }
        }
        h5 {
          @media (max-width: 1100px) {
            font-size: small;
          }
        }
        @media (max-width: 1100px) {
          padding: 0 1em;
        }
      }
      .image-box {
        margin-top: -8%;
        .image-container {
          width: 100%;
          img {
            @media (max-width: 1100px) {
              width: 15em;
            }
          }
        }
        // width: 463px;
        // height: 462px;
      }
      @media (max-width: 1100px) {
        align-self: flex-start;
      }
    }
    .container-2 {
      flex: 1;
      border-radius: 100px 20px 20px 20px;
      background: #ffeaea;
      display: flex;
      flex-direction: row;
      @media (max-width: 490px) {
        flex-direction: column-reverse;
      }
      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 3%;
        align-items: center;
        text-align: center;
        h2 {
          @media (max-width: 1100px) {
            font-size: medium;
          }
        }
        h5 {
          @media (max-width: 1100px) {
            font-size: small;
          }
        }
        @media (max-width: 1100px) {
          padding: 0;
        }
        @media (max-width: 490px) {
          padding: 2em 0 0 0;
        }
      }
      .image-box {
        margin-top: -3%;
        .image-container {
          width: 100%;
          img {
            @media (max-width: 1100px) {
              width: 15em;
            }
          }
        }
        // width: 463px;
        // height: 462px;
      }
      @media (max-width: 1100px) {
        align-self: flex-start;
      }
    }
  }
  .image_text_container-3 {
    display: flex;
    overflow: hidden;
    padding-top: 5em;
    gap: 2em;
    @media (max-width: 490px) {
      flex-direction: column;
      gap: 5em;
    }
    .container-1 {
      width: 60%;
      border-radius: 100px 20px 20px 20px;
      background: #f2eaff;
      display: flex;
      flex-direction: row;
      @media (max-width: 490px) {
        width: 100%;
        flex-direction: column-reverse;
      }

      .text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10% 3%;
        align-items: center;
        text-align: center;
        h2 {
          @media (max-width: 1100px) {
            font-size: medium;
          }
        }
        h5 {
          @media (max-width: 1100px) {
            font-size: small;
          }
        }
        @media (max-width: 1100px) {
          padding: 0;
        }
        @media (max-width: 490px) {
          padding: 1em 0 0 1.2em;
        }
      }
      .image-box {
        margin-top: -5%;
        .image-container {
          width: 100%;
          img {
            @media (max-width: 1100px) {
              width: 15em;
            }
          }
        }
        // width: 463px;
        // height: 462px;
      }
      @media (max-width: 1100px) {
        align-self: flex-start;
      }
    }
    .container-2 {
      flex: 1;
      border-radius: 100px 20px 20px 20px;
      background: #fff5df;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1% 3%;
        align-items: center;
        h2 {
          @media (max-width: 1100px) {
            font-size: medium;
          }
        }
        h5 {
          @media (max-width: 1100px) {
            font-size: small;
          }
        }
        @media (max-width: 1100px) {
          padding: 0 1em;
        }
      }
      .image-box {
        margin-top: -8%;
        .image-container {
          width: 100%;
          img {
            @media (max-width: 1100px) {
              width: 12em;
            }
          }
        }
      }
      @media (max-width: 1100px) {
        align-self: flex-start;
      }
    }
  }
}
