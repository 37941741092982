.phone {
  padding-top: 2em;
  display: flex;
  gap: 2em;
  @media (max-width: 770px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 426px) {
    flex-direction: column-reverse;
  }
  @media (min-width: 1919px) {
    justify-content: center;
    align-items: center;
  }
  .left {
    width: 50%;
    display: flex;
    padding: 5em;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    @media (max-width: 770px) {
      width: 100%;
    }
    @media (max-width: 490px) {
      width: 100%;
      padding: 0;
    }
    @media (max-width: 1100px) {
      padding: 2em;
    }

    h3 {
      font-family: Oxanium;
      font-size: large;
    }
    h2 {
      font-family: Oxanium;
      font-size: xxx-large;
      padding-bottom: 0.2em;
      color: #4b4a4b;
      @media (max-width: 1100px) {
        font-size: xx-large;
      }
      @media (max-width: 490px) {
        font-size: large;
      }
    }
    .tilted-rectangles {
      display: flex;
      gap: 10px;
      padding: 0 0 1em 0.8em;
      div {
        width: 1em;
        height: 1.2em;
        background-color: #53778d;
        transform: skewX(-20deg);
        border-radius: 5px;
      }
    }
    p {
      font-family: "Oxanium";
    }
    .imgstor {
      display: flex;
      gap: 5em;
      @media (max-width: 490px) {
        flex-direction: column;
        gap: 1em;
      }
      @media (max-width: 770px) {
        margin-top: 5em;
      }
      margin-top: auto;
      @media (min-width: 1919px) {
        margin-top: 15em;
      }
      img {
        width: 8em;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    padding: 5em;
    justify-content: end;
    @media (max-width: 770px) {
      padding: 0;
      justify-content: center;
    }
    .right-img{
      background-color: #F5F5F5;
      display: flex;
      justify-content: center;
      border-radius: 1em 6em 1em 1em;
      align-items: center;
      .imphon {
        width: 70%;
        padding: 1em 0;
      }
    }
  }
}
