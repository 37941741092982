* {
  margin: 0;
  padding: 0;
}
.Faculty-container {
  width: 100%;
  height: 100%;
  padding: 2em 5em;
  gap: 1em;
  background: white;
  .tilted-rectangles {
    display: flex;
    gap: 10px;
    padding: 0 0 1em 0.8em;
    div {
      width: 3em;
      height: 4em;
      background-color: #53778d;
      transform: skewX(-20deg);
      border-radius: 5px;
      @media (max-width: 490px) {
        width: 1em;
        height: 1.2em;
      }
    }
  }
  .Faculty-txt {
    padding-top: 1em;
    font-size: 200%;
    @media (max-width: 490px) {
      font-size: 85%;
    }
  }
  h5 {
    width: auto;
    font-family: Oxanium;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;

    span {
      font-family: Oxanium;
      font-size: 18px;
      font-weight: 700;
      line-height: 22.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }
  }

  h1 {
    font-family: Oxanium;
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
    margin: 40px 0px 20px 0px;
    @media (max-width: 490px) {
      font-size: 20px;
      margin: 0;
    }
  }

  @media (max-width: 769px) {
    padding: 2em 1em;
  }
  // @media (max-width: 426px) {
  //   padding:2em 1em;
  // }
  @media (max-width: 1100px) {
    padding: 0 1em;
  }
}
