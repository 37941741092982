@keyframes rotateSpinner {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  
    .circle {
      position: absolute;
      border-radius: 50%;
      border: 5px dashed #a463f2;
      animation: rotateSpinner 70s linear infinite;
  
      &.circle1 {
        width: 100%;
        height: 100%;
      }
  
      &.circle2 {
        width: 90%;
        height: 90%;
        animation-direction: reverse;
      }
  
      &.circle3 {
        width: 80%;
        height: 80%;
        animation-duration: 30s;
      }
  
      &.circle4 {
        width: 70%;
        height: 70%;
        animation-duration: 40s;
        animation-direction: reverse;
      }
    }
    
    @media (max-width: 1100px) {
     width: 11em;
     height: 11em;
    }
  }