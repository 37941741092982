.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35em, 1fr));
  gap: 2em;
  padding: 2em;
  @media (max-width: 490px) {
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr)) !important;
    padding: 0;
    margin-bottom: 2em;
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  }
}

.card {
  background-color: #F5F5F5;
  overflow: hidden;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  &:hover {
    transform: translateY(-5px);
  }

  .card-image {
    width: 100%;
    height: 20em;
    object-fit: cover;
    @media (max-width: 1100px) {
      height: 15em;
    }
  }

  .card-content {
    padding: 1.5em;
    text-align: center;

    h3 {
      @media (max-width: 1100px) {
        font-size: 1em;
      }
      font-size: 1.8em;
      font-weight: bold;
      color: #4b4a4b;
      margin-bottom: 1em;
    }

    p {
      @media (max-width: 1100px) {
        font-size: 0.8em;
      }
      font-size: 1.4em;
      color: #555;
    }
  }
}
