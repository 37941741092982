* {
  margin: 0;
  padding: 0;
}

.upper-container {
  display: flex;
  width: 100%;
  height: 308px;
  background: #485d5b;
  justify-content: space-around;
  align-items: center;

  h3 {
    width: auto;
    height: 20px;
    font-family: Oxanium;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .contact-column {
    width: 185px;
    height: auto;

    a {
      width: auto;
      height: 20px;
      display: flex;
      color: #ffffff;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
      text-decoration: none;
    }

    .contact-outer {
      width: 25px;
      height: 25px;
      background: #00ad9f;
      border-radius: 50%;
      padding: 3px;
      display: flex;
      align-items: center;
      border-radius: 50%;
    }
    .social {
      padding-top: 10px;

      h2 {
        font-family: Oxanium;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .social-tags {
        display: flex;
        gap: 10px;
        color: #ffffff;
      }
      .social-outer {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #ffffff;
        padding: 2px;
      }
    }
  }

  .text-column {
    width: 240px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      width: auto;
      height: 15px;
      font-family: Oxanium;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration: none;
      color: #fcfcfc;
    }

    .texts {
      width: 240px;
      display: flex;
      justify-content: space-between;
    }
  }

  .address-column {
    width: 336px;
    height: 150px;

    p {
      width: 334.69px;
      height: 80px;
      font-family: Oxanium;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #e7e7e7;
    }
  }
}

.lower-container {
  display: flex;
  width: 100%;
  height: 79px;
  background: #333333;
  justify-content: space-around;
  align-items: center;

  .lower-container-text {
    width: auto;
    height: 15px;
    font-family: "Oxanium";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
  }
}

@media (max-width: 768px) {
  .upper-container {
flex-wrap: wrap;
    height: auto; 
    padding: 20px;

    .contact-column,
    .text-column{
      width: 50%; 

    }
    
    .address-column {
      align-items: start;
      text-align: left;
     p{
      font-family: Oxanium;
     }
     
    }

    .contact-column {

      .social {
        h2 {
          font-size: 10px; // Adjust font size for smaller screens
        }

        .social-outer {
          width: 25px; // Reduce size of social icons
          height: 25px;
        }
      }
    }

    .text-column {
      gap: 1.4em; 

      a {
        font-size: 10px;
        line-height: 12px;
      }
    }

    .address-column {
      margin-top: 20px;
      margin-right:auto;
      p {
        font-family: Oxanium;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .lower-container {
    flex-direction: column; 
    height: auto; 
    padding: 10px;

    .lower-container-text {
      text-align: center; 
      height: 100%;
      font-size: 14px; 
      line-height: 18px;
    }
  }
}

@media (max-width: 480px) {
  .upper-container {
    padding: 10px;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-right: auto;

    .contact-column {
      .contact-outer {
        width: 20px; // Further reduce contact icon size
        height: 20px;
      }

      .social {
        h2 {
          font-size: 8px; // Smaller font size
        }

        .social-outer {
          width: 20px; // Further reduce social icon size
          height: 20px;
        }
      }
    }

    .contact-column,
    .text-column{
      width: 100%; 
      margin-bottom: 1em;

    }

    .text-column {
      a {
        font-size: 8px; 
        line-height: 10px;
      }
    }

    .address-column {
      display: flex;
      flex-direction: column;
      margin-right: auto;
     p{
      font-family: Oxanium;
     }
    
    }
  }

  .lower-container {
    padding: 5px;

    .lower-container-text {
      height: 100%;
      font-size: 16px; 
      line-height: 24px;
    }
  }
}