.cardContainer {
  display: flex;
  flex-direction: column;
  .container {
    display: flex;
    flex-direction: column;
    .image_text_container-3 {
      display: flex;
      overflow: hidden;
      padding-top: 5em;
      gap: 2em;
      .container-1 {
        border-radius: 300px 20px 20px 20px;
        background: #fff5df;
        display: flex;
        flex-direction: row;
        height: 23em;
        @media (max-width: 490px) {
          flex-direction: column;
          gap: 20em;
          height: auto;
        }

        .text-box {
          flex: 1;
          font-family: "Oxanium";
          display: flex;
          flex-direction: column;
          padding: 2% 2% 2% 20%;
          text-align: justify;
          align-items: flex-end;
          gap: 0.5em;

          @media (max-width: 1100px) {
            padding: 1% 1% 1% 10%;
          }
          h2 {
            color: #4b4a4b;
          }
          h5 {
            color: #6e6d6d;
          }
          p {
            font-weight: 500;
            font-family: "Oxanium";
            color: #313131;
          }
        }
        .image-box {
          width: 30%;
          margin-top: -2%;
          .image-spinner-container {
            position: relative;
            .spinner {
              position: absolute;
              top: 4em;
              left: 7em;
            }
            .image-container {
              position: absolute;
              right: -2em;
              z-index: 1;
              width: 100%;
              img {
                @media (max-width: 1100px) {
                  width: 15em;
                }
              }
            }
          }
        }
      }
    }
    .image_text_container-4 {
      display: flex;
      overflow: hidden;
      padding-top: 5em;
      gap: 2em;
      .container-2 {
        border-radius: 20px 300px 20px 20px;
        background: #ffeaea;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 23em;
        @media (max-width: 490px) {
          flex-direction: column-reverse;
          gap: 23em;
          height: auto;
        }
        .text-box {
          width: 58vw;
          font-family: "Oxanium";
          display: flex;
          flex-direction: column;
          padding: 2% 20% 2% 2%;
          text-align: justify;
          gap: 0.5em;
          @media (max-width: 490px) {
            width: 100%;
          }

          @media (max-width: 1100px) {
            padding: 1% 12% 1% 1%;
          }
          h2 {
            color: #4b4a4b;
          }
          h5 {
            color: #6e6d6d;
          }
          p {
            padding-top: 1em;
            font-weight: 500;
            font-family: "Oxanium";
            color: #313131;
          }
          @media (min-width: 1919px) {
            width: 70vw;
          }
        }
        .image-box {
          flex: 1;
          margin-top: -2%;
          @media (max-width: 490px) {
            margin-top: -11%;
          }
          .image-spinner-container {
            position: relative;
            .spinner {
              position: absolute;
              top: 4em;
              left: -5em;
              @media (max-width: 490px) {
                left: 0;
              }
            }
            .image-container {
              position: absolute;
              right: 2em;
              z-index: 1;
              width: 100%;
              img {
                @media (max-width: 1100px) {
                  width: 22em;
                }
              }
              @media (max-width: 490px) {
                right: 1em !important;
              }
              @media (max-width: 1100px) {
                right: 4em;
              }
            }
          }
          @media (min-width: 1919px) {
            flex: none;
          }
        }
      }
    }
  }
}
