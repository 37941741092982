.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .container-1 {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
      font-family: Oxanium;
      font-weight: 600;
      font-size: 2em;
      color: #4b4a4b;
    }
    .txt {
      color: #313131;
      padding: 0 20em;
      font-size: 100%;
      font-family: Oxanium;
      font-weight: 400;
      @media (max-width: 1100px) {
        padding: 0 2em;
      }
    }
  }
  .container-2 {
    display: flex;
    gap: 1.5em;
    .flex-container {
      display: flex;
      gap: 1.5em;
      @media (max-width: 770px) {
        flex-direction: column;
      }
      .card {
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5em;
        border-radius: 1em;
        background-color: #f5f5f5;
        @media (max-width: 1100px) {
          padding: 3em;
        }
        .icon {
          font-size: 2em;
          color: #ff6b00;
        }
        h3 {
          font-family: Oxanium;
          font-weight: 600;
          font-size: 1.5em;
          color: #4b4a4b;
        }
        .txt {
          color: #313131;
          font-size: 100%;
          font-family: Oxanium;
          font-weight: 400;
        }
      }
    }
  }
}
