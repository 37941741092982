@font-face {
  font-family: Oxanium;
  src: url('../../assets/fonts/Outfit-VariableFont_wght.ttf');
}

.shell {
    width: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'Oxanium', cursive;
    background-color: #f2f2f2;
    ::-webkit-scrollbar {
      width: 8px;
    }
  
    ::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      border-radius: 10px;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: #c0c0c0;
      border-radius: 10px;
    }
  
    ::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a0;
    }
  
  }
  
  * {
    box-sizing: border-box;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: 'Oxanium';
    padding: 0;
    margin: 0;
  }
  
  ul,
  ul li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }