@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@400;500;600&display=swap");

.navbar {
  font-family: "Oxanium", cursive;
  position: sticky;
  z-index: 1000;
  background-color: white;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo img {
  width: 120px;
  height: auto;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    background-color: white;
    flex-direction: column;
    padding: 2rem;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease;
  }

  &.active {
    @media (max-width: 768px) {
      right: 0;
    }
  }
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;

  li a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: #00d2c0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}

.navbar-buttons {
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.signup-btn,
.login-btn {
  font-family: "Oxanium", cursive;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.signup-btn {
  background-color: #00d2c0;
  color: white;
  border: none;
  width: 105px;
  height: 30px;
  padding: 6px 25px 6px 25px;
  border-radius: 20px;

  &:hover {
    background-color: #00b8a6;
  }
}

.login-btn {
  font-family: Oxanium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  background: white;
  color: #4b4a4b;
  border: none;
}

.navbar-hamburger {
  display: none;
  cursor: pointer;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }
}

/* Media Query for Smaller Screens */
@media (max-width: 480px) {
  .navbar-container {
    max-width: 100%;
    padding: 1.5rem;
  }

  .navbar-logo img {
    width: 100px;
  }

  .navbar-menu {
    position: fixed;
    width: 50%;
    flex-direction: column;
  }
}
