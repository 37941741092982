.faqContainer {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0px 0px 130px;
  background: #fffff5;
  font-family: Arial, sans-serif;
  margin-bottom: 30px;

  h1 {
    width: 708px;
    height: 55px;
    font-family: Oxanium;
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
    margin-bottom: 20px;
  }

  .faqItem {
    display: flex;
    align-items: center;
    margin: 20px 0px 0px auto;
    padding: 20px;
    gap: 50px;
  }

  .faqContent {
    flex: 1;
    padding-right: 20px;

    h3 {
      width: 663px;
      height: 20px;
      font-family: Oxanium;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4a4b;
    }

    p {
      margin-top: 10px;
      width: 915px;
      height: 40px;
      font-family: Oxanium;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #313131;
    }
  }

  .faqImage {
    width: 48px;
    height: 48px;
    margin-bottom: auto;
  }
}

@media (max-width: 768px) {
  .faqContainer {
    padding: 30px 20px; // Reduce padding for smaller screens

    h1 {
      width: 300px; // Allow heading to use full width
      font-size: 32px; // Adjust font size for smaller screens
      line-height: 40px;
    }

    .faqItem {
      display: flex;
      gap: 10rem;
      align-items: center;
    
    

    .faqContent {
      padding-right: 0; 

      h3 {
        width: 300px;
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4b4a4b;
      }

      p {
        width: 300px;
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #313131;
      }
    }

    .faqImage {
      width: 40px; // Reduce image size
      height: 40px;
    }
  }
}
}

@media (max-width: 480px) {
  .faqContainer {
    padding: 20px 10px;

    h1 {
      font-size: 24px;
      line-height: 32px;
    }

    .faqItem {
      display: flex;
      gap: 15px; 
    }

    .faqContent {
    
      h3 {
        font-size: 12px;
        line-height: 16px;
      }

      p {
        max-width: 250px;
        height: auto;
        font-size: 10px;
        line-height: 16px;
      }
    }

    .faqImage {
      width: 32px; 
      height: 32px;
    }
  }
}
