* {
  margin: 0;
  padding: 0;
}
.Faculty-container {
  width: 100%;
  height: 100%;
  padding: 2em 5em;
  gap: 1em;
  background: white;
  .tilted-rectangles {
    display: flex;
    gap: 10px;
    padding: 0 3em;
    div {
      width: 3em;
      height: 4em;
      background-color: #53778d;
      transform: skewX(-20deg);
      border-radius: 5px;
      @media (max-width: 490px) {
        width: 1em;
        height: 1.2em;
      }
    }
    @media (max-width: 490px) {
      padding: 0;
    }
  }
  h1 {
    font-family: Oxanium;
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b4a4b;
    padding: 0.5em 1em;
    @media (max-width: 490px) {
      font-size: 20px;
      margin: 0;
      padding: 0;
    }
  }

  @media (max-width: 769px) {
    padding: 2em 1em;
  }
  // @media (max-width: 426px) {
  //   padding:2em 1em;
  // }
  @media (max-width: 1100px) {
    padding: 0 1em;
  }
}
