.container {
  display: flex;
  flex-direction: column;
  .learning-section {
    display: flex;
    padding: 40px;
    background-color: #ffffff;
    @media (max-width: 490px) {
      flex-direction: column;
      padding: 0;
    }
    @media (min-width: 1919px) {
      justify-content: center;
      align-items: center;
    }
    .image {
      display: flex;
      width: 35%;
      @media (max-width: 770px) {
        width: 40%;
      }
      @media (max-width: 490px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .learning-box {
      padding-top: 2em;
      flex: 1;
      .left {
        margin-left: auto;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: end;

        height: 100%;
        @media (max-width: 1100px) {
          width: 85%;
        }
        @media (max-width: 490px) {
          width: 100%;
          padding: 0;
        }

        h3 {
          font-family: Oxanium;
          font-size: large;
        }
        h2 {
          font-family: Oxanium;
          font-size: xxx-large;
          padding-bottom: 0.2em;
          color: #4b4a4b;
          @media (max-width: 1100px) {
            font-size: xx-large;
          }
          @media (max-width: 490px) {
            font-size: large;
          }
        }
        .tilted-rectangles {
          display: flex;
          gap: 10px;
          padding: 0 0 1em 0.8em;
          div {
            width: 1em;
            height: 1.2em;
            background-color: #53778d;
            transform: skewX(-20deg);
            border-radius: 5px;
          }
        }
        p {
          font-family: "Oxanium";
          padding: 1em;
        }
        .imgstor {
          margin-top: auto;
          @media (max-width: 770px) {
            margin-top: 2em;
          }
          @media (min-width: 1919px) {
            margin-top: 15em;
          }
          .signup-btn {
            background-color: #00d2c0;
            color: white;
            border: none;
            font-family: "Oxanium", cursive;
            padding: 1rem 1.5rem;
            border-radius: 50px;
            font-weight: 800;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
              background-color: #00b8a6;
            }
          }
        }
      }
    }
  }
  .learning-section2 {
    display: flex;
    padding: 40px;
    background-color: #ffffff;

    @media (min-width: 1919px) {
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 490px) {
      padding: 0;
      flex-direction: column;
      gap: 2em;
    }
    .learning-box {
      padding-top: 2em;
      width: 60%;
      .left {
        margin-right: auto;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: start;
        height: 100%;
        @media (max-width: 1100px) {
          width: 85%;
        }

        @media (max-width: 490px) {
          width: 100%;
          padding: 0;
        }

        h3 {
          font-family: Oxanium;
          font-size: large;
        }
        h2 {
          font-family: Oxanium;
          font-size: xxx-large;
          padding-bottom: 0.2em;
          color: #4b4a4b;
          @media (max-width: 1100px) {
            font-size: xx-large;
          }
          @media (max-width: 490px) {
            font-size: large;
          }
        }
        .tilted-rectangles {
          display: flex;
          gap: 10px;
          padding: 0 0 1em 0.8em;
          div {
            width: 1em;
            height: 1.2em;
            background-color: #53778d;
            transform: skewX(-20deg);
            border-radius: 5px;
          }
        }
        p {
          font-family: "Oxanium";
          // padding: 1em;
        }
        .imgstor {
          margin-top: auto;
          @media (max-width: 770px) {
            margin-top: 2em;
          }
          @media (min-width: 1919px) {
            margin-top: 15em;
          }
          .signup-btn {
            background-color: #00d2c0;
            color: white;
            border: none;
            font-family: "Oxanium", cursive;
            padding: 1rem 1.5rem;
            border-radius: 50px;
            font-weight: 800;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
              background-color: #00b8a6;
            }
          }
        }
      }
    }
    .image {
      flex: 1;
      .animated-dots-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
      }

      .animated-dots {
        position: relative;
        width: clamp(200px, 20vw, 300px); /* Responsive size for image */
        height: clamp(200px, 20vw, 300px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;

        @media (min-width: 1919px) {
          width: clamp(300px, 20vw, 400px); /* Larger size for large screens */
          height: clamp(300px, 20vw, 400px);
        }

        .image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        &::before {
          content: "";
          position: absolute;
          top: -2em; /* Position 2em outside the image */
          left: -2em;
          width: calc(100% + 4em); /* Expand size for 2em padding */
          height: calc(100% + 4em);
          border-radius: 50%;
          background: repeating-conic-gradient(
            transparent 15deg,
            #c7bbcc 35deg,
            transparent 20deg
          );
          mask: radial-gradient(circle, transparent 60%, black 70%);
          animation: rotateDots 10s linear infinite;
          z-index: 1;
        }
      }

      @keyframes rotateDots {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      /* Responsive Breakpoints */
      @media screen and (max-width: 768px) {
        .animated-dots {
          width: clamp(150px, 30vw, 250px); /* Smaller size for tablets */
          height: clamp(150px, 30vw, 250px);
        }

        .animated-dots::before {
          top: -1.5em; /* Adjust space for smaller screens */
          left: -1.5em;
          width: calc(100% + 3em);
          height: calc(100% + 3em);
        }
      }

      @media screen and (max-width: 480px) {
        .animated-dots {
          width: clamp(
            100px,
            40vw,
            200px
          ); /* Smaller size for mobile devices */
          height: clamp(100px, 40vw, 200px);
        }

        .animated-dots::before {
          top: -1em; /* Adjust space for smaller devices */
          left: -1em;
          width: calc(100% + 2em);
          height: calc(100% + 2em);
        }
      }
    }
  }
}
