.testimonials {
  padding: 1em 0;

  h2 {
    font-weight: 600;
    color: #4b4a4b;
    font-family: Oxanium;
    font-size: 3em;
    margin-bottom: 2rem;
    @media (max-width: 490px) {
      font-size: 1em;}
  }

  &__list {
    display: flex;
    gap: 0.5em;
    justify-content: space-evenly;
    @media (max-width: 426px) {
      flex-wrap: wrap;
    }
    @media (max-width: 1100px) {
      flex-wrap: wrap;
    }
  }
  .testimonial {
    width: 90%;

    .box {
      width: 100%;
      height: 34em;
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 600" width="100%" height="100%" preserveAspectRatio="none"><path d="M 0 250 L 0 150 C 0 50 0 0 100 0 L 200 0 C 300 0 300 50 300 100 C 300 200 400 150 400 250 L 400 450 C 400 500 400 550 350 550 C 300 550 300 550 50 350 Q 0 300 0 250" fill="%233F5C59" /></svg>')
        no-repeat center/cover;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      
      .txt {
        font-weight: 500;
        font-family: Oxanium;
        margin: 3em 10em 0 2em;
        color: white;
        @media (max-width: 490px) {
          margin-right: 5em;
        }
      }
      .img-txt {
        display: flex;
        justify-content: space-around;
        .img {
          width: 50%;
          .inner-image {
            width: 12em;
            height: 12em;
          }
        }
        .itxt {
          padding-left: 3em;
          h3 {
            font-family: Oxanium;
            font-weight: 600;
            color: white;
          }
          span {
            font-weight: 300;
            font-size: 1em;
            font-family: Oxanium;
            color: white;
          }
        }
      }
      @media (max-width: 490px) {
        width: 18em !important;
        height: 25em !important;  
      }
      @media (max-width: 1100px) {
        width: 20em;
      }
      @media (min-width: 1919px) {
        width: 32em;
      }
    }
  }
}
