.cardContainer {
  display: flex;
  flex-direction: column;
  .image_text_container-3 {
    display: flex;
    overflow: hidden;
    padding-top: 5em;
    gap: 2em;
    .container {
      border-radius: 20px 300px 20px 20px;
      background: #f2eaff;
      display: flex;
      flex-direction: column;
      @media (max-width: 490px) {
        border-radius: 20px 200px 20px 20px;
      }
      .container-1 {
        display: flex;
        flex-direction: row;
        @media (max-width: 490px) {
          flex-direction: column-reverse;
          gap: 18em;
        }
        .text-box {
          width: 58vw;
          font-family: "Oxanium";
          display: flex;
          flex-direction: column;
          padding: 2% 20% 2% 2%;
          text-align: justify;
          gap: 0.5em;
          @media (max-width: 490px) {
            // height: 40%;
            width: 100%;
          }

          @media (max-width: 1100px) {
            padding-right: 5%;
          }
          h2 {
            color: #4b4a4b;
          }
          h5 {
            color: #6e6d6d;
          }
          p {
            font-weight: 500;
            padding-top: 1em;
            font-family: "Oxanium";
            color: #313131;
          }
          @media (min-width: 1919px) {
            width: 70vw;
          }
        }
        .image-box {
          flex: 1;
          margin-top: -4%;
          @media (max-width: 490px) {
            margin-top: -10%;
          }

          .image-spinner-container {
            position: relative;
            .spinner {
              position: absolute;
              top: 4em;
              left: -1em;
              @media (max-width: 490px) {
                left: 0;
              }
            }
            .image-container {
              position: absolute;
              z-index: 1;
              width: 100%;
              img {
                @media (max-width: 1100px) {
                  width: 15em;
                }
              }
            }
          }
          @media (min-width: 1919px) {
            flex: none;
          }
        }
      }
      .container-2 {
        padding-top: 5em;
        // ResourcesActivities.scss
        @media (max-width: 490px) {
          padding-top: 1em;
        }

        .resources-activities-container {
          padding: 2rem 0em;

          .flex-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 3em 10%;
            @media (max-width: 490px) {
              flex-direction: column;
            }
          }

          .card {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 300px;
            border-radius: 15px;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            max-width: 37%; /* Optional max width for large screens */
            transition: transform 0.3s ease;
            @media (max-width: 490px) {
              max-width: none;
            }

            &:hover {
              transform: scale(1.02);
            }

            .card-content {
              background: rgba(0, 0, 0, 0.5);
              padding: 1rem;
              text-align: center;
              color: #fff;
              width: 100%;
            }

            .card-title {
              font-family: "Oxanium";
              font-weight: 700;
              margin-bottom: 0.5rem;
            }

            .card-description {
              font-weight: 300;
              font-family: "Oxanium";
              font-size: 0.9rem;
              margin: 1em 4em;

              @media (max-width: 1100px) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
