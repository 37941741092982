html, body {
  width: 100%;
  overflow-x: hidden; 
}

.main-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: #f2f2f2;
}

.main-content {
  flex: 1;
}
